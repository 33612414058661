var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "下单时间" },
                  model: {
                    value: _vm.form.time_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "time_type", $$v)
                    },
                    expression: "form.time_type",
                  },
                },
                _vm._l(_vm.orderTime, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("class-select", {
                ref: "classSelect",
                on: { "class-select-change": _vm.changeClass },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("brand-select", {
                ref: "brandSelect",
                attrs: { duoxuan: "" },
                on: { "brand-select-change": _vm.changeBrand },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "商品类型" },
                  model: {
                    value: _vm.form.goods_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "goods_type", $$v)
                    },
                    expression: "form.goods_type",
                  },
                },
                _vm._l(_vm.goodsTypeSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("staff-select", {
                ref: "staffSelect",
                on: { "change-staff": _vm.changeStaff },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("driver-select", {
                ref: "driverSelect",
                on: { "change-driver": _vm.changeDriver },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "出货仓库" },
                  model: {
                    value: _vm.form.depot_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "depot_id", $$v)
                    },
                    expression: "form.depot_id",
                  },
                },
                _vm._l(_vm.depotList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品生产日期:", prop: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time2,
                  callback: function ($$v) {
                    _vm.time2 = $$v
                  },
                  expression: "time2",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { clearable: "", placeholder: "订单来源" },
                  model: {
                    value: _vm.form.bill_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bill_type", $$v)
                    },
                    expression: "form.bill_type",
                  },
                },
                _vm._l(_vm.orderFrom, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { clearable: "", placeholder: "订单状态" },
                  model: {
                    value: _vm.form.bill_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bill_status", $$v)
                    },
                    expression: "form.bill_status",
                  },
                },
                _vm._l(_vm.orderStatus, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "结算方式" },
                  model: {
                    value: _vm.form.account_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "account_type", $$v)
                    },
                    expression: "form.account_type",
                  },
                },
                _vm._l(_vm.payTypeSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "是否结清" },
                  model: {
                    value: _vm.form.pay_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "pay_status", $$v)
                    },
                    expression: "form.pay_status",
                  },
                },
                _vm._l(_vm.payStatusList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "区域" },
                  model: {
                    value: _vm.form.area_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "area_id", $$v)
                    },
                    expression: "form.area_id",
                  },
                },
                _vm._l(_vm.regionList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "自定义客户分组" },
                  model: {
                    value: _vm.form.cust_group_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cust_group_id", $$v)
                    },
                    expression: "form.cust_group_id",
                  },
                },
                _vm._l(_vm.customClient, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "自定义商品分组" },
                  model: {
                    value: _vm.form.goods_group_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "goods_group_id", $$v)
                    },
                    expression: "form.goods_group_id",
                  },
                },
                _vm._l(_vm.customGoods, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("goods-search", {
                attrs: { "is-table": false },
                on: { "select-goods-all": _vm.selectGoods },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("client-search", {
                attrs: { w: 280 },
                on: { "select-val-alldata": _vm.selectClient },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入单号" },
                model: {
                  value: _vm.form.bill_code,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "bill_code", $$v)
                  },
                  expression: "form.bill_code",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "index", width: "50" },
          }),
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "",
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label == "单号"
                          ? _c(
                              "div",
                              {
                                staticClass: "underline",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOrderDetail(row.order_id)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row[item.prop]) + " ")]
                            )
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("advance-order", { ref: "advanceOrder" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }