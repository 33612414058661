<template>
  <!-- NAME[epic=销售] 预订单明细 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="100px"
    >
      <el-form-item label="" prop="">
        <el-select
          v-model="form.time_type"
          clearable
          style="width: 140px"
          placeholder="下单时间"
        >
          <el-option
            v-for="(i, idx) in orderTime"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <class-select
          ref="classSelect"
          @class-select-change="changeClass"
        ></class-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <brand-select
          ref="brandSelect"
          duoxuan
          @brand-select-change="changeBrand"
        ></brand-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.goods_type"
          clearable
          style="width: 140px"
          placeholder="商品类型"
        >
          <el-option
            v-for="(i, idx) in goodsTypeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <staff-select
          ref="staffSelect"
          @change-staff="changeStaff"
        ></staff-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <driver-select
          ref="driverSelect"
          @change-driver="changeDriver"
        ></driver-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.depot_id"
          clearable
          style="width: 140px"
          placeholder="出货仓库"
        >
          <el-option
            v-for="(i, idx) in depotList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品生产日期:" prop="">
        <el-date-picker
          v-model="time2"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.bill_type"
          clearable
          style="width: 130px"
          placeholder="订单来源"
        >
          <el-option
            v-for="(i, idx) in orderFrom"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.bill_status"
          clearable
          style="width: 130px"
          placeholder="订单状态"
        >
          <el-option
            v-for="(i, idx) in orderStatus"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.account_type"
          clearable
          style="width: 140px"
          placeholder="结算方式"
        >
          <el-option
            v-for="(i, idx) in payTypeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.pay_status"
          clearable
          style="width: 140px"
          placeholder="是否结清"
        >
          <el-option
            v-for="(i, idx) in payStatusList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.area_id"
          clearable
          style="width: 140px"
          placeholder="区域"
        >
          <el-option
            v-for="(i, idx) in regionList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.cust_group_id"
          clearable
          style="width: 140px"
          placeholder="自定义客户分组"
        >
          <el-option
            v-for="(i, idx) in customClient"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="" prop="">
        <el-select
          v-model="form.goods_group_id"
          clearable
          style="width: 140px"
          placeholder="自定义商品分组"
        >
          <el-option
            v-for="(i, idx) in customGoods"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="" prop="">
        <goods-search
          :is-table="false"
          @select-goods-all="selectGoods"
        ></goods-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          :w="280"
          @select-val-alldata="selectClient"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input v-model="form.bill_code" placeholder="请输入单号"></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <!-- <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template> -->
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div
            v-if="item.label == '单号'"
            class="underline"
            @click="handleOrderDetail(row.order_id)"
          >
            {{ row[item.prop] }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <advance-order ref="advanceOrder"></advance-order>
  </div>
</template>
<script>
  import _ from 'lodash'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { downloadFile, postAction } from '@/api/Employee'
  import advanceOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'

  import ClassSelect from '@/baseComponents/classSelect'
  import BrandSelect from '@/baseComponents/brandSelect'
  import StaffSelect from '@/baseComponents/staffSelect'
  import DriverSelect from '@/baseComponents/driverSelect'
  import {day_n} from "@/utils/Time";
  export default {
    name: 'ListDetail',
    components: {
      GoodsSearch,
      ClientSearch,
      ClassSelect,
      BrandSelect,
      StaffSelect,
      DriverSelect,
      advanceOrder,
    },
    data() {
      return {
        loading: false,
        list: [],
        time: [],
        time2: [],
        regionList: [], //区域
        form: {
          pageSize: 10,
          pageNo: 1,
        },
        // 出货仓库下拉
        depotList: [],
        // 订单来源 1、车销 2、访销 3、网销 4、仓库直
        orderFrom: [
          // {
          //   id: 1,
          //   name: '车销',
          // },
          {
            id: 2,
            name: '访销',
          },
          {
            id: 3,
            name: '网销',
          },
          // {
          //   id: 4,
          //   name: '仓库直销',
          // },
        ],
        orderStatus: [
          {
            id: -1,
            name: '待审核',
          },
          {
            id: 1,
            name: '待派单',
          },
          {
            id: 2,
            name: '已派单',
          },
          {
            id: 3,
            name: '已到货',
          },
          {
            id: 4,
            name: '已作废',
          },
        ],
        orderTime: [
          {
            id: 'create_at',
            name: '下单时间',
          },
          {
            id: 'arrive_at',
            name: '到货时间',
          },
          {
            id: 'pay_status_time',
            name: '结清时间',
          },
          {
            id: 'delivery_at',
            name: '派送时间',
          },
        ],
        payStatusList: [
          {
            id: -1,
            name: '未结清',
          },
          {
            id: 1,
            name: '已结清',
          },
          {
            id: 2,
            name: '坏账',
          },
        ],
        payTypeSelect: [],
        goodsTypeSelect: [],
        customClient: [],
        customGoods: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        checkList: [
          '单号',
          '客户编码',
          '客户',
          '商品编码',
          '商品名称',
          '规格',
          '下单数量',
          '到货数量',
          '单位',
          '单价',
          '金额',
          '类型',
          '毛利',
          '毛利率',
          '条码',
          '业务员',
          '下单时间',
          '出货仓库',
          '送货人',
          '到货时间',
          '商品备注',
          '单据备注',
        ],
        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 2,
            label: '客户编码',
            prop: 'cust_code',
            width: '',
          },
          {
            order: 3,
            label: '客户',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 4,
            label: '商品编码',
            prop: 'goods_id',
            width: '',
          },
          {
            order: 5,
            label: '商品名称',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 6,
            label: '规格',
            prop: 'goods_specs',
            width: '',
          },
          {
            order: 7,
            label: '下单数量',
            prop: 'pre_quantity',
            width: '',
          },
          {
            order: 7,
            label: '到货数量',
            prop: 'quantity',
            width: '',
          },
          {
            order: 7,
            label: '单位',
            prop: 'unit_name',
            width: '',
          },
          {
            order: 7,
            label: '单价',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 7,
            label: '金额',
            prop: 'goods_money',
            width: '',
          },
          {
            order: 7,
            label: '类型',
            prop: 'goods_type_text',
            width: '',
          },
          {
            order: 7,
            label: '毛利',
            prop: 'gross_profit_amount',
            width: '',
          },
          {
            order: 7,
            label: '毛利率',
            prop: 'gross_profit',
            width: '',
          },
          {
            order: 7,
            label: '条码',
            prop: 'unit_code',
            width: '',
          },
          {
            order: 7,
            label: '业务员',
            prop: 'staff_name',
            width: '',
          },
          {
            order: 7,
            label: '下单时间',
            prop: 'create_at',
            width: '',
          },
          {
            order: 7,
            label: '出货仓库',
            prop: 'depot_name',
            width: '',
          },
          {
            order: 7,
            label: '送货人',
            prop: 'driver_name',
            width: '',
          },
          {
            order: 7,
            label: '到货时间',
            prop: 'arrive_at',
            width: '',
          },
          {
            order: 7,
            label: '商品备注',
            prop: 'info',
            width: '',
          },
          {
            order: 7,
            label: '单据备注',
            prop: 'order_remark',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      time2(val) {
        if (val) {
          this.form.production_start_time = val[0]
          this.form.production_end_time = val[1]
        } else {
          this.form.production_start_time = ''
          this.form.production_end_time = ''
        }
      },
    },
    created() {},
    mounted() {
      this.time = day_n(90)
      this.form.start_time = this.time[0]
      this.form.end_time = this.time[1]
      this.initSelect()
      this.fetchData()
      this.handlerregionList()
    },
    activated() {
      this.fetchData()
    },
    methods: {
      //区域
      handlerregionList() {
        postAction('saleAdmin/order-pre/option', { pageSize: -1 }).then(
          (res) => {
            console.log(res)
            this.regionList = res.data.cust_area
          }
        )
      },
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/saleAdmin/sale-order-form/order',
          this.form
        )
        this.total = totalCount
        this.list = data.list
        this.loading = false
      },
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      // 获取下拉数据
      initSelect() {
        this.getDepot()
        this.clientGroup()
        this.goodsGroup()
        // 获取商品类型
        this.getGoodsType()
        this.getPayType()
      },
      // 结算方式下拉
      getPayType() {
        postAction('/saleAdmin/order-pre/create-option').then((r) => {
          this.payTypeSelect = r.data.arr_account_type
        })
      },
      // 获取商品类型
      getGoodsType() {
        postAction('/saleAdmin/sale-form/goods-type-arr').then((r) => {
          this.goodsTypeSelect = r.data
        })
      },
      // 出货仓库下拉
      getDepot() {
        postAction('/saleAdmin/order-pre/create-option').then((r) => {
          console.log('ck', r)
          this.depotList = r.data.depot
        })
      },
      // 客户分组下拉
      clientGroup() {
        postAction('/baseAdmin/customer-group/index', { pageSize: -1 }).then(
          (r) => {
            this.customClient = r.data
          }
        )
      },
      // 商品分组下拉
      goodsGroup() {
        postAction('/baseAdmin/goods-group/index', { pageSize: -1 }).then(
          (r) => {
            this.customGoods = r.data
          }
        )
      },
      // 修改品类
      changeClass(v) {
        this.form.class_id = v
      },
      // 修改品牌
      changeBrand(v) {
        this.form.brand_id = v
      },
      // 修改业务员
      changeStaff(v) {
        this.form.staff_id = v
      },
      // 修改送货人
      changeDriver(v) {
        this.form.dirver_id = v
      },
      selectGoods(v) {
        console.log('', v)
        if (v.goods_id > 0) this.form.goods_id = v.goods_id
        else this.form.goods_id = ''
      },
      selectClient(v) {
        this.form.cust_id = v.id
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },

      handleExport() {
        //         /saleAdmin/sale-order-form/export-order
        // 导出-预订单-销售明细按单据
        downloadFile(
          '/saleAdmin/sale-order-form/export-order',
          '预订单-销售明细按单据.xlsx',
          this.form
        )
      },
      handleOrderDetail(id) {
        this.$refs.advanceOrder.id = id
        this.$refs.advanceOrder.isshowDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped></style>
